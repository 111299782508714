// MealPlanner.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Tabs, Tab, Select, MenuItem, InputLabel, FormControl, TextField, Button, Card, CardContent, Typography, FormGroup, FormControlLabel, Checkbox, Container, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Radio, RadioGroup } from '@material-ui/core';
import Spinner from './Spinner';
import "react-activity/dist/library.css";

const MealPlanner = () => {
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState('');
  // Macros
  const [calories, setCalories] = useState({ min: '', max: '' });
  const [protein, setProtein] = useState({ min: '', max: '' });
  const [carbs, setCarbs] = useState({ min: '', max: '' });
  const [fats, setFats] = useState({ min: '', max: '' });
  // Micros
  const [fiber, setFiber] = useState({ min: '', max: '' });
  const [calcium, setCalcium] = useState({ min: '', max: '' });
  const [potassium, setPotassium] = useState({ min: '', max: '' });
  const [cholesterol, setCholesterol] = useState({ min: '', max: '' });
  const [sugar, setSugar] = useState({ min: '', max: '' });
  const [sodium, setSodium] = useState({ min: '', max: '' });
  // Additional Info
  const [allergies, setAllergies] = useState([]);
  const [days, setDays] = useState(7);
  const [mealsPerDay, setMealsPerDay] = useState(3);
  const [results, setResults] = useState(null);
  const [sidebarView, setSidebarView] = useState('generate');
  const [recipeSearchCriteria, setRecipeSearchCriteria] = useState({
    calories: { min: 0, max: 300 },
    protein: { min: 30, max: 50 },
    fats: { min: 0, max: 5 },
    carbs: { min: 10, max: 20 },
    fiber: { min: null, max: null },
    potassium : { min: null, max: null },
    cholesterol : { min: null, max: null},
    calcium : { min: null, max: null },
    sugar : { min: null, max: null },
    sodium : { min: null, max: null },
    allergies: []
  });
  const [recipeGenerateCriteria, setRecipeGenerateCriteria] = useState({
    calories: { min: '', max: '' },
    protein: { min: '', max: '' },
    fats: { min: '', max: '' },
    carbs: { min: '', max: '' },
    fiber: { min: null, max: null },
    potassium : { min: null, max: null },
    cholesterol : { min: null, max: null},
    calcium : { min: null, max: null },
    sugar : { min: null, max: null },
    sodium : { min: null, max: null },
    allergies: [],
    description: '',
    cuisine: ''
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [country, setCountry] = useState(null);
  const [countryAutocomplete, setCountryAutocomplete] = useState(null);
  const [selectedRecipeDetails, setSelectedRecipeDetails] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);


  const googleMapsScript = useRef(null);

  const allergiesList = ['Peanuts', 'Tree Nuts', 'Milk', 'Eggs', 'Wheat', 'Soy', 'Fish', 'Shellfish'];
  const GCP_API_KEY = "AIzaSyBKvWsSVopNnJFZ_uagSYHes6LniSWfqJk";

  useEffect(() => {
    axios.get('https://chatapi-staging.completumhealth.com/get-ml-members').then(response => setMembers(response.data));
  }, []);

  useEffect(() => {
    console.log("use effect selected day", selectedDay);
  }, [selectedDay]);

  useEffect(() => {
    console.log("use effect results", results);
  }, [results]);

  useEffect(() => {
    console.log("isGenerating Change: ", isGenerating);
  }, [isGenerating])

  const handleMemberChange = (event) => {
    const member = members.find(m => m.memberId === event.target.value);
    setSelectedMember(event.target.value);
    setCalories({ min: member.calorieRange.min, max: member.calorieRange.max });
    setProtein({ min: member.proteinRange.min, max: member.proteinRange.max });
    setCarbs({ min: member.carbRange.min, max: member.carbRange.max});
    setFats({ min: member.fatRange.min, max: member.fatRange.max});
    setAllergies(member.allergies);
  };

  const handleAllergyChange = (event) => {
    const { name, checked } = event.target;
    setAllergies(prevAllergies => 
      checked ? [...prevAllergies, name] : prevAllergies.filter(allergy => allergy !== name)
    );
  };

  const handleGenerateMealPlan = () => {
    axios.post('https://chatapi-staging.completumhealth.com/return-mealplan', { memberId: selectedMember, days, mealsPerDay })
      .then(response => setResults(response.data));
  };

  const handleGenerateRecipe = async () => {
    setIsGenerating(true);
    try{
      console.log("generating recipe");
      const response = await axios.post('http://0.0.0.0:8080/generate-recipe', recipeGenerateCriteria);
      setRecipes([response.data])
    }
    catch(error) {
      console.log(error);
    }
    finally{
      setIsGenerating(false);
    }
  };

  const handleSearchRecipe = () => {
    axios.post('https://chatapi-staging.completumhealth.com/search-recipe', recipeSearchCriteria)
      .then(response => setRecipes(response.data));
  };

  const handleDialogOpen = (day) => {
    setSelectedDay(day);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedDay(null);
    setSelectedRecipe(null);
    setDialogOpen(false);
  };
  
  const handleAddRecipeToDay = () => {
    console.log("selected recipe", selectedRecipe);
    console.log("selected day", selectedDay);
    if (selectedRecipe !== null && selectedDay !== null) {
      const recipe = recipes[selectedRecipe];
      console.log("recipe ", recipe);
  
      setResults(prevResults => {
        const updatedMealPlan = [...prevResults.meal_plan];
        updatedMealPlan[selectedDay] = [...(updatedMealPlan[selectedDay] || []), recipe];
        
        return {
          ...prevResults,
          meal_plan: updatedMealPlan
        };
      });
  
      handleDialogClose();
    }
  };

  const handleRemoveRecipeFromDay = (dayIndex, mealIndex) => {
    setResults(prevResults => {
      const updatedMealPlan = [...prevResults.meal_plan];
      updatedMealPlan[dayIndex] = updatedMealPlan[dayIndex].filter((_, index) => index !== mealIndex);
  
      return { ...prevResults, meal_plan: updatedMealPlan };
    });
  };
  
  const handleDetailsDialogOpen = (recipe) => {
    setSelectedRecipeDetails(recipe);
    setDetailsDialogOpen(true);
  };
  
  const handleDetailsDialogClose = () => {
    setSelectedRecipeDetails(null);
    setDetailsDialogOpen(false);
  };
  
  

  return (
    <div className="meal-planner" style={{padding: 20}}>
      <div className="member-search">
        <FormControl fullWidth>
          <InputLabel>Member</InputLabel>
          <Select value={selectedMember} onChange={handleMemberChange}>
            {members.map((member,index) => (
              <MenuItem key={index} value={member.memberId}>{member.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="member-info">
        <TextField label="Calories Min" value={calories.min} onChange={e => setCalories({ ...calories, min: e.target.value })} fullWidth />
        <TextField label="Calories Max" value={calories.max} onChange={e => setCalories({ ...calories, max: e.target.value })} fullWidth />
        <TextField label="Protein Min" value={protein.min} onChange={e => setProtein({ ...protein, min: e.target.value })} fullWidth />
        <TextField label="Protein Max" value={protein.max} onChange={e => setProtein({ ...protein, max: e.target.value })} fullWidth />
        <TextField label="Carbs Min" value={carbs.min} onChange={e => setCarbs({ ...carbs, min: e.target.value })} fullWidth />
        <TextField label="Carbs Max" value={carbs.max} onChange={e => setCarbs({ ...carbs, max: e.target.value })} fullWidth />
        <TextField label="Fats Min" value={fats.min} onChange={e => setFats({ ...fats, min: e.target.value })} fullWidth />
        <TextField label="Fats Max" value={fats.max} onChange={e => setFats({ ...fats, max: e.target.value })} fullWidth />
      </div>

      <div className="allergies">
        <FormControl component="fieldset">
          <FormGroup>
            {allergiesList.map(allergy => (
              <FormControlLabel
                key={allergy}
                control={
                  <Checkbox
                    checked={allergies.includes(allergy)}
                    onChange={handleAllergyChange}
                    name={allergy}
                  />
                }
                label={allergy}
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>

      <div className="meal-plan-controls">
        <TextField label="Days" type="number" value={days} onChange={e => setDays(e.target.value)} fullWidth />
        <TextField label="Meals per Day" type="number" value={mealsPerDay} onChange={e => setMealsPerDay(e.target.value)} fullWidth />
        <Button variant="contained" color="primary" onClick={handleGenerateMealPlan}>Generate Meal Plan</Button>
      </div>

      <div className="results">
        {results && results['meal_plan'].map((day, dayIndex) => (
          <Card key={dayIndex} className="day-card" style={{ margin: '20px 0' }}>
            <CardContent>
              <Typography variant="h5">Meal Plan for Day {dayIndex + 1}</Typography>
              <Grid container spacing={2}>
                {results && results["meal_plan"][dayIndex].map((meal, mealIndex) => (
                  <Grid item xs={12} sm={6} md={4} key={mealIndex}>
                    <Card className="meal-card">
                      <CardContent>
                        <Typography variant="h6">{meal.name}</Typography>
                        <Typography>Calories: {meal.calories}</Typography>
                        <Typography>Carbohydrates: {meal.carbohydrates_g}g</Typography>
                        <Typography>Fat: {meal.fat_g}g</Typography>
                        <Typography>Protein: {meal.protein_g}g</Typography>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleRemoveRecipeFromDay(dayIndex, mealIndex)}
                        >
                          Remove
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
                <Button variant="contained" color="secondary" onClick={() => handleDialogOpen(dayIndex)}>Add Recipe</Button>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </div>


      <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
        <DialogTitle id="form-dialog-title">Add Recipe</DialogTitle>
        <DialogContent>
          <Tabs value={sidebarView} onChange={(e, newValue) => {
            setRecipes([]);
            setSidebarView(newValue);
            }} centered>
            <Tab label="Generate Recipe" value="generate" />
            <Tab label="Search Recipe" value="search" />
          </Tabs>
          {sidebarView === 'generate' && (
            <div className="generate-recipe">
              <TextField label="Description" value={recipeGenerateCriteria.description} onChange={e => setRecipeGenerateCriteria({ ...recipeGenerateCriteria, description: e.target.value })} fullWidth />
              <TextField label="Calories Min" value={recipeGenerateCriteria.calories.min} onChange={e => setRecipeGenerateCriteria({ ...recipeGenerateCriteria, calories: { ...recipeGenerateCriteria.calories, min: e.target.value } })} fullWidth />
              <TextField label="Calories Max" value={recipeGenerateCriteria.calories.max} onChange={e => setRecipeGenerateCriteria({ ...recipeGenerateCriteria, calories: { ...recipeGenerateCriteria.calories, max: e.target.value } })} fullWidth />
              <TextField label="Protein Min" value={recipeGenerateCriteria.protein.min} onChange={e => setRecipeGenerateCriteria({ ...recipeGenerateCriteria, protein: { ...recipeGenerateCriteria.protein, min: e.target.value } })} fullWidth />
              <TextField label="Protein Max" value={recipeGenerateCriteria.protein.max} onChange={e => setRecipeGenerateCriteria({ ...recipeGenerateCriteria, protein: { ...recipeGenerateCriteria.protein, max: e.target.value } })} fullWidth />
              <TextField label="Carbs Min" value={recipeGenerateCriteria.carbs.min} onChange={e => setRecipeGenerateCriteria({ ...recipeGenerateCriteria, carbs: { ...recipeGenerateCriteria.carbs, min: e.target.value } })} fullWidth />
              <TextField label="Carbs Max" value={recipeGenerateCriteria.carbs.max} onChange={e => setRecipeGenerateCriteria({ ...recipeGenerateCriteria, carbs: { ...recipeGenerateCriteria.carbs, max: e.target.value } })} fullWidth />
              <TextField label="Fats Min" value={recipeGenerateCriteria.fats.min} onChange={e => setRecipeGenerateCriteria({ ...recipeGenerateCriteria, fats: { ...recipeGenerateCriteria.fats, min: e.target.value } })} fullWidth />
              <TextField label="Fats Max" value={recipeGenerateCriteria.fats.max} onChange={e => setRecipeGenerateCriteria({ ...recipeGenerateCriteria, fats: { ...recipeGenerateCriteria.fats, max: e.target.value } })} fullWidth />
              <TextField label="Cuisine" value={recipeGenerateCriteria.cuisine} onChange={e => setRecipeGenerateCriteria({ ...recipeGenerateCriteria, cuisine: e.target.value})} fullWidth />
              <FormControl component="fieldset">
                <FormGroup>
                  {allergiesList.map(allergy => (
                    <FormControlLabel
                      key={allergy}
                      control={
                        <Checkbox
                          checked={recipeGenerateCriteria.allergies.includes(allergy)}
                          onChange={e => {
                            const { name, checked } = e.target;
                            setRecipeGenerateCriteria(prevCriteria => ({
                              ...prevCriteria,
                              allergies: checked
                                ? [...prevCriteria.allergies, name]
                                : prevCriteria.allergies.filter(a => a !== name)
                            }));
                          }}
                          name={allergy}
                        />
                      }
                      label={allergy}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              {
                isGenerating ? <Spinner></Spinner> :  <Button variant="contained" color="primary" onClick={handleGenerateRecipe}>Generate Recipe</Button>
              }
            </div>
          )}
          {sidebarView === 'search' && (
            <div className="search-recipe">
              <TextField label="Calories Min" value={recipeSearchCriteria.calories.min} onChange={e => setRecipeSearchCriteria({ ...recipeSearchCriteria, calories: { ...recipeSearchCriteria.calories, min: e.target.value } })} fullWidth />
              <TextField label="Calories Max" value={recipeSearchCriteria.calories.max} onChange={e => setRecipeSearchCriteria({ ...recipeSearchCriteria, calories: { ...recipeSearchCriteria.calories, max: e.target.value } })} fullWidth />
              <TextField label="Protein Min" value={recipeSearchCriteria.protein.min} onChange={e => setRecipeSearchCriteria({ ...recipeSearchCriteria, protein: { ...recipeSearchCriteria.protein, min: e.target.value } })} fullWidth />
              <TextField label="Protein Max" value={recipeSearchCriteria.protein.max} onChange={e => setRecipeSearchCriteria({ ...recipeSearchCriteria, protein: { ...recipeSearchCriteria.protein, max: e.target.value } })} fullWidth />
              <TextField label="Carbs Min" value={recipeSearchCriteria.carbs.min} onChange={e => setRecipeSearchCriteria({ ...recipeSearchCriteria, carbs: { ...recipeSearchCriteria.carbs, min: e.target.value } })} fullWidth />
              <TextField label="Carbs Max" value={recipeSearchCriteria.carbs.max} onChange={e => setRecipeSearchCriteria({ ...recipeSearchCriteria, carbs: { ...recipeSearchCriteria.carbs, max: e.target.value } })} fullWidth />
              <TextField label="Fats Min" value={recipeSearchCriteria.fats.min} onChange={e => setRecipeSearchCriteria({ ...recipeSearchCriteria, fats: { ...recipeSearchCriteria.fats, min: e.target.value } })} fullWidth />
              <TextField label="Fats Max" value={recipeSearchCriteria.fats.max} onChange={e => setRecipeSearchCriteria({ ...recipeSearchCriteria, fats: { ...recipeSearchCriteria.fats, max: e.target.value } })} fullWidth />
              <FormControl component="fieldset">
                <FormGroup>
                  {allergiesList.map(allergy => (
                    <FormControlLabel
                      key={allergy}
                      control={
                        <Checkbox
                          checked={recipeSearchCriteria.allergies.includes(allergy)}
                          onChange={e => {
                            const { name, checked } = e.target;
                            setRecipeSearchCriteria(prevCriteria => ({
                              ...prevCriteria,
                              allergies: checked
                                ? [...prevCriteria.allergies, name]
                                : prevCriteria.allergies.filter(a => a !== name)
                            }));
                          }}
                          name={allergy}
                        />
                      }
                      label={allergy}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <Button variant="contained" color="primary" onClick={handleSearchRecipe}>Search Recipe</Button>
            </div>
          )}
          <Typography variant="h6" style={{ marginTop: '20px' }}>Recipes</Typography>
          <RadioGroup value={selectedRecipe} onChange={e => {
            console.log(parseInt(e.target.value));
            setSelectedRecipe(parseInt(e.target.value));
          }}>
            {recipes.map((recipe, index) => (
              <div>
              <FormControlLabel
                key={index}
                value={index}
                control={<Radio />}
                label={`${recipe.name} (Calories: ${recipe.calories}, Protein: ${recipe.protein ? recipe.protein : recipe.protein_g}g, Carbs: ${recipe.carbs ? recipe.carbs : recipe.carbohydrates_g}g, Fats: ${recipe.fat ? recipe.fat : recipe.fat_g}g)`}
              />
              <p>{recipe.description ? recipe.description : ""}</p>
              <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDetailsDialogOpen(recipe)}
                  >
                    Details
                  </Button>
              </div>
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddRecipeToDay} color="primary">Add Recipe</Button>
          <Button onClick={handleDialogClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={detailsDialogOpen}
        onClose={handleDetailsDialogClose}
        aria-labelledby="details-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="details-dialog-title">Recipe Details</DialogTitle>
        <DialogContent>
          {selectedRecipeDetails && (
            <>
              <Typography variant="h6">{selectedRecipeDetails.name}</Typography>
              <Typography>Instructions: {selectedRecipeDetails.instructions ? selectedRecipeDetails.instructions : "No Instructions"}</Typography>
              {/* Add other details if needed */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDetailsDialogClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default MealPlanner;

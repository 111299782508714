import React from 'react';
import RecipeCard from './RecipeCard';

const MealPlannerScreen = () => {

    return (
        <RecipeCard></RecipeCard>
    );
};

export default MealPlannerScreen
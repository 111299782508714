import React, { useEffect, useState } from 'react';

// Assuming restrictions data is passed as a prop to this component
const RestrictionsList = ({ restrictionsData }) => {
  const [selectedRestrictions, setSelectedRestrictions] = useState([]);

  const fetchProfile = async () => {
    const query = `
        {
          myProfile {
            programName
            restrictions {
              id
              name
              isOnProgram
              subcategory
              slugname 
            }
          }
        }
      `;
    try {
        const response = await fetch('https://production.suggestic.com/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token e0f38722a7a0600e4cdd982e92ad09a45a666ead', // Replace with actual auth token
            'sg-user': '1489d7f9-23c8-4169-927f-99d554bed5cd', // Replace with actual auth token
            // Include other headers as required by your GraphQL server
          },
          body: JSON.stringify({ query })
        });

        const jsonResponse = await response.json();

        if (jsonResponse.errors) {
          console.log(jsonResponse.errors)
        } else {
          const activeRestrictions = jsonResponse.data.myProfile.restrictions
          .map(r => r.id);
        setSelectedRestrictions(activeRestrictions);
        }
      } catch (err) {
        console.log(err)
      }
  };
  
  useEffect(() => {
    fetchProfile();
  }, []);

  const handleCheckboxChange = (restrictionId) => {
    setSelectedRestrictions((prevSelected) => {
      if (prevSelected.includes(restrictionId)) {
        return prevSelected.filter((id) => id !== restrictionId);
      } else {
        return [...prevSelected, restrictionId];
      }
    });
  };

  // Function to handle the GraphQL mutation request
  const updateProfileRestrictions = async () => {
    console.log(selectedRestrictions)
    const mutation = `
      mutation {
        profileRestrictionsUpdate(
          restrictions: ${JSON.stringify(selectedRestrictions)}
        ) {
          success
        }
      }
    `;

    try {
      const response = await fetch('https://production.suggestic.com/graphql', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token e0f38722a7a0600e4cdd982e92ad09a45a666ead', // Replace with actual auth token
        'sg-user': '1489d7f9-23c8-4169-927f-99d554bed5cd' // Replace with actual sg-user value
        },
        body: JSON.stringify({
        query: mutation
        })
      });

      const result = await response.json();
      console.log(result)
      if (result.data && result.data.profileRestrictionsUpdate.success) {
        console.log('Restrictions updated successfully');
        // Handle success case
      } else {
        console.error('Failed to update restrictions');
        // Handle the failure case
      }
    } catch (error) {
      console.error('Error updating restrictions:', error);
      // Handle the error case
    }
  };
  return (
    <div>
      {restrictionsData.map((restriction) => (
        <label key={restriction.id}>
          <input
            type="checkbox"
            checked={selectedRestrictions.includes(restriction.id)}
            onChange={() => handleCheckboxChange(restriction.id)}
          />
          {restriction.name}
        </label>
      ))}
      <button onClick={updateProfileRestrictions}>Update Restrictions</button>
    </div>
  );
};

export default RestrictionsList;
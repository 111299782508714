import React, { useState, useEffect } from 'react';

const MealPlanSettings = () => {
    const [userData, setUserData] = useState({});
    const [nutrition, setNutrition] = useState({
      totalCalories: '',
      carbs: '',
      protein: '',
      fat: ''
    });

    const handleInputChange = (e) => {
      // console.log(e.target.value)
      setNutrition({ ...nutrition, [e.target.name]: e.target.value });
      // console.log(nutrition)
    };

    const resetField = (fieldName) => {
      setNutrition({ ...nutrition, [fieldName]: '' });
    };

    const fetchData = async () => {
        const query = `
        {
          myProfile {
            debugMealPlanVariables {
              kcal
              conditions {
                procnt
                chocdf
                fat
                maxEnercKcal
                omega3
                procnt
              }
              format
            }
          }
        }
        
          `;
        try {
            const response = await fetch('https://production.suggestic.com/graphql', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token e0f38722a7a0600e4cdd982e92ad09a45a666ead', // Replace with actual auth token
                'sg-user': '1489d7f9-23c8-4169-927f-99d554bed5cd', // Replace with actual auth token
                // Include other headers as required by your GraphQL server
              },
              body: JSON.stringify({ query })
            });
    
            const jsonResponse = await response.json();
    
            if (jsonResponse.errors) {
              console.log(jsonResponse.errors)
            } else {
              console.log(jsonResponse.data)
              const newUserData = jsonResponse.data.myProfile.debugMealPlanVariables;
              setUserData(newUserData)
              const nutritionData = {totalCalories: newUserData.kcal,
                carbs: Math.floor(newUserData.conditions.chocdf / newUserData.kcal * 100),
                protein: Math.floor(newUserData.conditions.procnt / newUserData.kcal * 100),
                fat: Math.floor(newUserData.conditions.fat / newUserData.kcal * 100)
              }
              setNutrition(nutritionData);

            }
          } catch (err) {
            console.log(err)
          }
      };
    
    useEffect(() => {
      fetchData();
    }, [])

    const updateNutrition = async () => {
      // console.log(nutrition.carbs);
      // console.log(nutrition.fat);
      // let updateObj = {
      //   calories : null,
      //   protein: null,
      //   carbs: null,
      //   fat: null
      // }
      // if (nutrition.totalCalories != '') {
      //   updateObj.calories = parseInt(nutrition.totalCalories)
      // }
      // if (nutrition.protein != '') {
      //   updateObj.protein = Math.floor(parseInt(nutrition.protein)/parseInt(nutrition.totalCalories) * 100)
      // }
      // if (nutrition.carbs != '') {
      //   updateObj.carbs = Math.floor(parseInt(nutrition.carbs)/parseInt(nutrition.totalCalories) * 100)
      // }
      // if (nutrition.fat != '') {
      //   updateObj.fat = Math.floor(parseInt(nutrition.fat)/parseInt(nutrition.totalCalories) * 100)
      // }
      const query = `
      mutation updateMealPlanSettings {
        updateMealPlanSettings(
          update: { calories: ${nutrition.totalCalories}, protein: ${nutrition.protein}, carbs: ${nutrition.carbs}, fat: ${nutrition.fat} }
        ) {
          success
          message
        }
      }
      
        `;
      console.log(query);
      try {
          const response = await fetch('https://production.suggestic.com/graphql', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Token e0f38722a7a0600e4cdd982e92ad09a45a666ead', // Replace with actual auth token
              'sg-user': '1489d7f9-23c8-4169-927f-99d554bed5cd', // Replace with actual auth token
              // Include other headers as required by your GraphQL server
            },
            body: JSON.stringify({ query })
          });
  
          const jsonResponse = await response.json();
  
          if (jsonResponse.errors) {
            console.log(jsonResponse.errors)
          } else {
            console.log(jsonResponse.data)

          }
        } catch (err) {
          console.log(err)
        }
    };
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '600px', margin: 'auto' }}>
    <div>
      <label>Total Calories:</label>
      <input 
        type="number" 
        name="totalCalories" 
        value={nutrition.totalCalories} 
        onChange={handleInputChange} 
      />
      <button onClick={() => resetField('totalCalories')}>Clear</button>
    </div>
    <div>
      <label>Carbs (%):</label>
      <input 
        type="number" 
        name="carbs" 
        value={nutrition.carbs} 
        onChange={handleInputChange} 
      />
      <button onClick={() => resetField('carbs')}>Clear</button>
    </div>
    <div>
      <label>Protein (%):</label>
      <input 
        type="number" 
        name="protein" 
        value={nutrition.protein} 
        onChange={handleInputChange} 
      />
      <button onClick={() => resetField('protein')}>Clear</button>
    </div>
    <div>
      <label>Fat (%):</label>
      <input 
        type="number" 
        name="fat" 
        value={nutrition.fat} 
        onChange={handleInputChange} 
      />
      <button onClick={() => resetField('fat')}>Clear</button>
    </div>
    <button onClick={updateNutrition}>Update Settings</button>
  </div>
  )
}

export default MealPlanSettings
import React, { useState, useEffect } from 'react';

const MealCard = ({ day, date, calories, meals }) => {
  const [mealsInfo, setMealsInfo] = useState(meals);
  const [forceUpdate, setForceUpdate] = useState(0);


  const replaceMeal = async (mealIndex, id) => {
    console.log("Replace Meal")
    // Mock function to fetch alternative meal. Replace with your actual API request.
    const fetchAlternativeMeal = async () => {
      const query = `
      {
        recipeSwapOptions(
          recipeId: "${id}"
      
        ) {
          similar (size:2) {
            id
            name
            serving
            nutritionalInfo{calories protein fat carbs}
          }
        }
      }
      
        `;
      try {
          const response = await fetch('https://production.suggestic.com/graphql', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Token e0f38722a7a0600e4cdd982e92ad09a45a666ead', // Replace with actual auth token
              'sg-user': '1489d7f9-23c8-4169-927f-99d554bed5cd', // Replace with actual auth token
              // Include other headers as required by your GraphQL server
            },
            body: JSON.stringify({ query })
          });
  
          const jsonResponse = await response.json();
  
          if (jsonResponse.errors) {
            console.log(jsonResponse.errors)
          } else {
            console.log(jsonResponse.data)
            const newMeal = {
              "meal": mealsInfo[mealIndex].meal,
              "calories": jsonResponse.data.recipeSwapOptions.similar[0].nutritionalInfo.calories * 100 * jsonResponse.data.recipeSwapOptions.similar[0].serving, // returns in kcal
              "recipe" : {
                "id" : jsonResponse.data.recipeSwapOptions.similar[0].id,
                "name" : jsonResponse.data.recipeSwapOptions.similar[0].name,
                "nutritionalInfo" : jsonResponse.data.recipeSwapOptions.similar[0].nutritionalInfo
              }
            }
            // console.log(newMeal);
            return newMeal
          }
        } catch (err) {
          console.log(err)
        }
    };

    try {
      const alternativeMeal = await fetchAlternativeMeal();
      console.log("Alternative Meal")
      console.log(alternativeMeal)
      // Create a new array for the updated meals
      const updatedMeals = mealsInfo.map((meal, index) => 
        index === mealIndex ? alternativeMeal : meal
      );
      setMealsInfo(updatedMeals);
      setForceUpdate(forceUpdate + 1);
    } catch (error) {
      console.error('Error fetching alternative meal:', error);
      // Handle error (e.g., show error message to user)
    }
  };
  

  useEffect(() => {
    // console.log("Meals state updated:", mealsInfo);
  }, [mealsInfo]);

  return (
    <div className="card">
      <h3>Day {day} - {new Date(date).toLocaleDateString()}</h3>
      <p>Total Calories: {calories.toFixed(2)}</p>
      <div>
        {mealsInfo.map((meal, index) => (
          <div key={index}>
            <h4>{meal.meal.charAt(0).toUpperCase() + meal.meal.slice(1)}</h4>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p>Name: {meal.recipe.name}</p>
              <button onClick={() => replaceMeal(index, meal.recipe.id)}>Replace</button>
            </div>
            <p>Calories: {Math.ceil(meal.calories)}</p>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p>Protein: {Math.floor(meal.recipe.nutritionalInfo.protein * meal.calories)}</p>
              <p>Carbs: {Math.floor(meal.recipe.nutritionalInfo.carbs * meal.calories)}</p>
              <p>Fat: {Math.floor(meal.recipe.nutritionalInfo.fat * meal.calories)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MealCard

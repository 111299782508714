import React, { useState } from 'react';
import axios from 'axios';
import './MealPlan.css'; // Import some basic styling
import UserRecipeCard from './UserRecipeCard';
import AddMealPopup from './AddMealPopup';
import DietaryRestrictionsDropdown from './DietaryRestrictionsDropdown';

const MealPlan = () => {
    const [mealPlan, setMealPlan] = useState([]);
    const [ranges, setRanges] = useState({
        min_calories: 1500,
        max_calories: 2000,
        min_fats: 0,
        max_fats: 70,
        min_proteins: 0,
        max_proteins: 100,
        min_carbs: 0,
        max_carbs: 300,
    });
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [currentDayIndex, setCurrentDayIndex] = useState(null);
    const [selectedRestrictions, setSelectedRestrictions] = useState([]);

    const addMeal = (dayIndex, meal) => {
        const newMealPlan = [...mealPlan];
        newMealPlan[dayIndex] = [...newMealPlan[dayIndex], meal];
        setMealPlan(newMealPlan);
        setShowPopup(false);
    };

    const addDay = async () => {
        try {
            setLoading(true);
            const response = await axios.post('http://0.0.0.0:8080/daily-mealplan', ranges, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            console.log(response.data);
            setMealPlan([...mealPlan, response.data]);
        } catch (error) {
            console.error("Error fetching meal plan:", error);
        } finally {
            setLoading(false);
        }
    };

    const deleteMeal = (dayIndex, mealIndex) => {
        const newMealPlan = [...mealPlan];
        newMealPlan[dayIndex].splice(mealIndex, 1);
        setMealPlan(newMealPlan);
    };

    const deleteDay = (dayIndex) => {
        const newMealPlan = [...mealPlan];
        newMealPlan.splice(dayIndex, 1);
        setMealPlan(newMealPlan);
    };

    const handleSelectRestrictions = (restrictions) => {
        setSelectedRestrictions(restrictions);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRanges({
            ...ranges,
            [name]: Number(value),
        });
    };

    const fetchMealPlan = async () => {
        try {
            setLoading(true);
            const response = await axios.post('http://0.0.0.0:8080/find-mealplan', ranges, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            console.log(response.data);
            setMealPlan(response.data);
        } catch (error) {
            console.error("Error fetching meal plan:", error);
        } finally {
            setLoading(false);
        }
    };

    const generateRecipe= async () => {
        const request = {
            "input" : "A recipe with chicken and zuchinni"
        }
        try {
            setLoading(true);
            const response = await axios.post('http://0.0.0.0:8080/generate-recipe', request, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching generating recipe:", error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="meal-plan-container">
            <div className="form" style={{flexDirection: 'row', maxWidth: '70%'}}>
                <label>
                    Min Calories:
                    <input type="number" name="min_calories" value={ranges.min_calories} onChange={handleInputChange} />
                </label>
                <label>
                    Max Calories:
                    <input type="number" name="max_calories" value={ranges.max_calories} onChange={handleInputChange} />
                </label>
                <label>
                    Min Fats (g):
                    <input type="number" name="min_fats" value={ranges.min_fats} onChange={handleInputChange} />
                </label>
                <label>
                    Max Fats (g):
                    <input type="number" name="max_fats" value={ranges.max_fats} onChange={handleInputChange} />
                </label>
                <label>
                    Min Proteins (g):
                    <input type="number" name="min_proteins" value={ranges.min_proteins} onChange={handleInputChange} />
                </label>
                <label>
                    Max Proteins (g):
                    <input type="number" name="max_proteins" value={ranges.max_proteins} onChange={handleInputChange} />
                </label>
                <label>
                    Min Carbs (g):
                    <input type="number" name="min_carbs" value={ranges.min_carbs} onChange={handleInputChange} />
                </label>
                <label>
                    Max Carbs (g):
                    <input type="number" name="max_carbs" value={ranges.max_carbs} onChange={handleInputChange} />
                </label>
                <DietaryRestrictionsDropdown onSelectRestrictions={handleSelectRestrictions} />
                {loading ? <text>Loading...</text> : 
                <div>
                <button onClick={fetchMealPlan}>Generate Meal Plan</button>
                <button onClick={addDay}>Add Day</button>
                <button onClick={generateRecipe}>Generate Recipe</button>
                </div>
                }
            </div>
            {mealPlan.length > 0 && (
                <div className="meal-plan">
                    {mealPlan.length > 0 && (
                        <div className="meal-plan">
                            {mealPlan.map((day, dayIndex) => (
                                <div key={dayIndex} className="meal-day">
                                    <h2>Day {dayIndex + 1}</h2>
                                    <button onClick={() => deleteDay(dayIndex)}>Delete Day</button>
                                    {day.map((meal, mealIndex) => (
                                        <div key={mealIndex}>
                                            <UserRecipeCard recipe_={meal} />
                                            <button onClick={() => deleteMeal(dayIndex, mealIndex)}>Delete Meal</button>
                                        </div>
                                    ))}
                                    <button onClick={() => { setCurrentDayIndex(dayIndex); setShowPopup(true); }}>Add Meal</button>
                                </div>
                            ))}
                        </div>
                    )}
                    {showPopup && <AddMealPopup dayIndex={currentDayIndex} onClose={() => setShowPopup(false)} onAddMeal={addMeal} />}
                </div>
            )}
        </div>
    );
};

export default MealPlan;

import React, {useState} from 'react'
import './chatsettings.css'

const ChatSettings = () => {
    const [temperature, setTemperature] = useState(0);
    const [prompt, setPrompt] = useState("You are a chatbot named SAHEJ. You are a diabetes specialist responsible for helping my clients improve their diabetes. You only answer questions related to diabetes care. You will read the user's info, conversation notes and recent messages, and then you will provide a detailed answer. You will then end your response with a follow-up or leading question. Do not reveal that you know any information about the user."); 

    return (
      <div className="container">
        <h3>Chat Settings</h3>
        <div>
          <label htmlFor="promptInput" className="label">Prompt:</label>
          <textarea
          id="promptInput"
          className="textarea"
          placeholder="Enter your prompt here"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          rows="3" // Starting number of rows
          style={{ resize: 'none', overflow: 'hidden' }} // Prevent manual resize
        />
        </div>
  
        <div>
            <label htmlFor="ragRadio" className="label">RAG</label>
          <input type="radio" id="ragRadio" name="ragOption" value="RAG" className="radio" />
        </div>
  
        <div>
          <label htmlFor="ragChunks" className="label">RAG Chunks:</label>
          <textarea id="ragChunks" className="textarea read-only" value="Dummy data for RAG Chunks" readOnly />
        </div>
  
        <div className="range-wrapper">
          <label>Temperature</label>
          <input
            type="range"
            id="temperatureSlider"
            min="0"
            max="1"
            step="0.01"
            value={temperature}
            onChange={(e) => setTemperature(e.target.value)}
            className="range"
          />
          <span>{temperature}</span>
        </div>
      </div>
    );
}

export default ChatSettings
import React, { useState, useEffect } from 'react';
import MealCard from './MealCard'; // Make sure to create a file for the Card component and import it here.
import './mealplan.css'

const MealPlan = () => {
  const [mealPlanData, setMealPlanData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const query = `
    { mealPlan { day date calories meals { calories meal recipe { id name nutritionalInfo{protein fat carbs} } } } }
      `;
    try {
        const response = await fetch('https://production.suggestic.com/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token e0f38722a7a0600e4cdd982e92ad09a45a666ead', // Replace with actual auth token
            'sg-user': '1489d7f9-23c8-4169-927f-99d554bed5cd', // Replace with actual auth token
            // Include other headers as required by your GraphQL server
          },
          body: JSON.stringify({ query })
        });

        const jsonResponse = await response.json();

        if (jsonResponse.errors) {
          console.log(jsonResponse.errors)
        } else {
          console.log(jsonResponse.data.mealPlan);
          setMealPlanData(jsonResponse.data.mealPlan);
        }
      } catch (err) {
        console.log(err)
      }
      setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateMealPlan = async () => {
    const query = `
    mutation { generateSimpleMealPlan(ignoreLock: true) { success message } }
      `;
    try {
        const response = await fetch('https://production.suggestic.com/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token e0f38722a7a0600e4cdd982e92ad09a45a666ead', // Replace with actual auth token
            'sg-user': '1489d7f9-23c8-4169-927f-99d554bed5cd', // Replace with actual auth token
            // Include other headers as required by your GraphQL server
          },
          body: JSON.stringify({ query })
        });

        const jsonResponse = await response.json();

        if (jsonResponse.errors) {
          console.log(jsonResponse.errors)
        } else {
          fetchData();
        }
      } catch (err) {
        console.log(err)
      }
  };


  return (
    <div>
      <div className="meal-plan-container">
        {mealPlanData && mealPlanData.map((dayPlan, index) => (
          <div>
          <MealCard key={index} {...dayPlan} />
          </div>
          ))}
      </div>
      <button onClick={updateMealPlan}>Generate Simple Meal Plan</button>
          </div>
          );
          };
export default MealPlan

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AddMealPopup = ({ dayIndex, onClose, onAddMeal }) => {
    const [calories, setCalories] = useState({ min: 0, max: 1000 });
    const [protein, setProtein] = useState({ min: 50, max: 100 });
    const [fats, setFats] = useState({ min: 0, max: 3 });
    const [carbs, setCarbs] = useState({ min: 0, max: 100 });
    const [meals, setMeals] = useState([]);
    const [loading, setLoading] = useState(false);

    const searchMeals = async () => {
        setLoading(true);
        try{
            const response = await axios.post('http://0.0.0.0:8080/search_meals', {
                calories, protein, fats, carbs
            });
            console.log(response.data);
            setMeals(response.data);
        } catch (error){
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     console.log("Meals... ", meals)
    // }, [meals])

    return (
        <div className="popup">
            <div className="popup-inner">
                <h2>Search for Meals</h2>
                <div>
                    <label>Calories: </label>
                    <input type="number" value={calories.min} onChange={e => setCalories({ ...calories, min: e.target.value })} placeholder="Min" />
                    <input type="number" value={calories.max} onChange={e => setCalories({ ...calories, max: e.target.value })} placeholder="Max" />
                </div>
                <div>
                    <label>Protein: </label>
                    <input type="number" value={protein.min} onChange={e => setProtein({ ...protein, min: e.target.value })} placeholder="Min" />
                    <input type="number" value={protein.max} onChange={e => setProtein({ ...protein, max: e.target.value })} placeholder="Max" />
                </div>
                <div>
                    <label>Fats: </label>
                    <input type="number" value={fats.min} onChange={e => setFats({ ...fats, min: e.target.value })} placeholder="Min" />
                    <input type="number" value={fats.max} onChange={e => setFats({ ...fats, max: e.target.value })} placeholder="Max" />
                </div>
                <div>
                    <label>Carbs: </label>
                    <input type="number" value={carbs.min} onChange={e => setCarbs({ ...carbs, min: e.target.value })} placeholder="Min" />
                    <input type="number" value={carbs.max} onChange={e => setCarbs({ ...carbs, max: e.target.value })} placeholder="Max" />
                </div>
                {
                    loading ? <p>Loading...</p> :
                    <div>
                        <button onClick={searchMeals}>Search</button>
                        <button onClick={onClose}>Close</button>
                    </div>
                }
                <div>
                    {meals.length > 0 && meals.map((meal, index) => (
                        <div key={index} onClick={() => onAddMeal(dayIndex, meal)}>
                            <h3>{meal.name}</h3>
                            <p>Calories: {meal.calories}</p>
                            <p>Protein: {meal.protein_g}</p>
                            <p>Fats: {meal.fat_g}</p>
                            <p>Carbs: {meal.carbohydrates_g}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AddMealPopup;

import React, { useState } from 'react';

const DietaryRestrictionsDropdown = ({ onSelectRestrictions }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRestrictions, setSelectedRestrictions] = useState([]);

    const restrictions = [
        "Vegetarian",
        "Vegan",
        "Gluten-Free",
        "Dairy-Free",
        "Nut-Free",
        "Halal",
        "Kosher"
    ];

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (restriction) => {
        let updatedRestrictions = [...selectedRestrictions];
        if (updatedRestrictions.includes(restriction)) {
            updatedRestrictions = updatedRestrictions.filter(item => item !== restriction);
        } else {
            updatedRestrictions.push(restriction);
        }
        setSelectedRestrictions(updatedRestrictions);
        onSelectRestrictions(updatedRestrictions);
    };

    return (
        <div className="dropdown">
            <button onClick={handleToggleDropdown}>
                Dietary Restrictions
            </button>
            {isOpen && (
                <div className="dropdown-menu">
                    {restrictions.map((restriction, index) => (
                        <label key={index}>
                            <input
                                type="checkbox"
                                value={restriction}
                                checked={selectedRestrictions.includes(restriction)}
                                onChange={() => handleCheckboxChange(restriction)}
                            />
                            {restriction}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DietaryRestrictionsDropdown;

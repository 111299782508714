import React, { useState, useEffect } from 'react';
import axios from 'axios';

function UserSelector({ onSelectUser }) {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios.get('http://0.0.0.0:8080/users')
            .then(response => setUsers(response.data))
            .catch(error => console.error('Error fetching users:', error));
    }, []);

    return (
        <select onChange={(e) => onSelectUser(e.target.value)}>
            <option value="">Select a User</option>
            {users.map(user => (
                <option key={user._id} value={user._id}>{user.name}</option>
            ))}
        </select>
    );
}

export default UserSelector;

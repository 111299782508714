import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './chat.css';

const Chat = () => {
    const [chatHistory, setChatHistory] = useState([]);
    const [apiKey, setApiKey] = useState('7ef0c02e-9f9e-4258-b587-d4efcc17fde8');
    const [userId, setUserId] = useState('65806ba73f3a54e332ddbd81');
    const [newMessage, setNewMessage] = useState('');
    //audio
    const [recording, setRecording] = useState(false);
    const [transcript, setTranscript] = useState('');
    const [shouldLog, setShouldLog] = useState(false);
    const recognitionRef = useRef(null);
  
    const [isLoading, setIsLoading] = useState(false);

    // Example function to handle like/dislike actions
    const handleLikeDislike = (chatId, action) => {
      console.log(`Chat ID: ${chatId}, Action: ${action}`);
      // You can add logic here to update the state or perform other actions
    };
  
    const loginUser = async () => {
      setChatHistory([])
      // Trigger fetching chat messages associated with this userId
      const payload = {
        userId : userId
      }
      try {
            axios.post('http://0.0.0.0:8080/previous-chat', payload, {
              headers: {
                'x-api-key': apiKey,
              },
            }).then((response) => {
              console.log(response)
              setChatHistory(response.data);
            }).catch((error) => {
              alert("API Key not valid: ".concat(error))
            });
            // if(chatHistory.length === 0) {
            //   axios.post('https://chatapi-staging.completumhealth.com/welcome-message', payload, {
            //     headers: {
            //       'x-api-key': apiKey,
            //     },
            //   }).then((res) => {
            //     setChatHistory([{id : Date.now(), speaker: "SEHAJ", message: res.data["response"]}]);
            //   }).catch((error) => {
            //     alert("User ID not valid: ".concat(error))
            //   });
            // }
          } catch (error) {
            console.error('Could not fetch chat history:', error);
          }
      setUserId(userId);
    };
  
    const addChatMessage = async () => {
      try {
        setIsLoading(true);
        axios.post('http://0.0.0.0:8080/get-chat', {
          input: newMessage, 
          userId: userId
        }, {
          headers: {
            'x-api-key': apiKey
          }
        }).then((response) => {
          setIsLoading(false);
          // Add the new user message to the local chat history
          setChatHistory(prevChatHistory => [...prevChatHistory, { id: Date.now(), speaker: userId, message: newMessage }]);
          
          // Assume the server sends back the bot's reply in the response.
          // You might need to adjust this depending on the actual structure of the server response.
          if (response.data && response.data["response"]) {
            setChatHistory(prevChatHistory => [...prevChatHistory, { id: Date.now() + 1, speaker: 'SAHEJ', message: response.data["response"]}]);
          }
          
          // Clear the input field
          setNewMessage('');
        }).catch((error) => {
          alert(error)
        });
    
      } catch (error) {
        setIsLoading(false)
        console.error('Failed to add message:', error);
      }
    };
  
    //AUDIO
    // useEffect to capture when recording stops
    useEffect(() => {
      if (!recording && shouldLog) {
        console.log('Final transcript:', transcript);
        setNewMessage(transcript);
        setShouldLog(false);  // Reset the shouldLog state
      }
    }, [recording]);
  
    const startRecording = () => {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      recognition.interimResults = false;  // Set to true if you want intermediate results
      recognitionRef.current = recognition;
  
      recognition.onstart = () => {
        setRecording(true);
      };
  
      recognition.onresult = (event) => {
        const currentTranscript = event.results[0][0].transcript;
        setTranscript(currentTranscript);
      };
  
      recognition.onend = () => {
        setRecording(false);
      };
  
      recognition.onerror = (event) => {
        console.log('Error occurred in recognition:', event.error);
      };
  
      recognition.start();
    };
  
    const stopRecording = () => {
      if (recognitionRef.current) {
        setShouldLog(true);  // Set shouldLog state to true when stopRecording is called
        recognitionRef.current.stop();
        recognitionRef.current = null;
      }
    };
  
    
  
    return (
      <div className="App">
        <div className="settings">
          <input type="text" placeholder="API Key" value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
          <input type="text" placeholder="User ID" value={userId} onChange={(e) => setUserId(e.target.value)} />
          <button onClick={loginUser}>Login</button>  {/* Login button */}
        </div>
  
        <h1>Sahej</h1>
        <div className="chat-history">
        {chatHistory.map((chat) => (
          <div key={chat.id} className={`chat-message ${chat.speaker === 'SAHEJ' ? 'bot' : 'user'}`}>
                <strong>{chat.speaker}:</strong> {chat.message}
                {chat.speaker === 'SAHEJ' ? 
                  <div className="chat-actions">
                    <button onClick={() => handleLikeDislike(chat.id, 'Link')}>Like</button>
                    <button onClick={() => handleLikeDislike(chat.id, 'Dislike')}>Dislike</button>
                  </div>
                  : ""
                }
              </div>
            ))}
          </div>
        <div className="chat-input">
          <input type="text" value={newMessage} onChange={(e) => setNewMessage(e.target.value)} placeholder="Type your message here..." disabled={(isLoading)? "disabled" : ""}/>
          {isLoading && <div>Loading...</div>}
          <button onClick={addChatMessage}>Send Message</button>
          {/* Microphone button */}
          {!recording ? (
            <button onClick={startRecording}>🎤 Start Recording</button>
          ) : (
            <button onClick={stopRecording}>🛑 Stop Recording</button>
          )}
        </div>
      </div>
    );
}

export default Chat
import React, { useState } from 'react';
import axios from 'axios';

function MealLogsInput({ userId, onLogsSubmitted }) {
    const [mealLogs, setMealLogs] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const logs = mealLogs.split(',').map(time => parseInt(time.trim()));
        axios.post(`http://localhost:5000/users/${userId}/meal_logs`, { meal_logs: logs })
            .then(response => onLogsSubmitted(response.data))
            .catch(error => console.error('Error submitting logs:', error));
    };

    return (
        <form onSubmit={handleSubmit}>
            <input 
                type="text" 
                value={mealLogs} 
                onChange={(e) => setMealLogs(e.target.value)} 
                placeholder="Enter meal times (e.g., 7,12,18)" 
                required 
            />
            <button type="submit">Submit Meal Logs</button>
        </form>
    );
}

export default MealLogsInput;

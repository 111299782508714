import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './RecipeCard.css'; // Import some basic styling

const RecipeCard = (recipe_) => {
    const [recipe, setRecipe] = useState(recipe_.recipe_);
    const [loading, setLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        if (recipe) {
            console.log("Loaded Recipe", recipe);
            setDataLoaded(true);
        }
    }, [recipe]);

    const fetchRandomRecipe = async () => {
        console.log("Fetching recipe");
        setLoading(true);
        try {
            const response = await axios.get('http://0.0.0.0:8080/random-recipe', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            let recipeData;
            if (typeof response.data === 'string') {
                try {
                    recipeData = JSON.parse(response.data);
                } catch (e) {
                    console.error("Failed to parse response data as JSON:", e);
                    throw e;
                }
            } else {
                recipeData = response.data;
            }
            console.log("Fetched Recipe Data:", recipeData);
            console.log("Fetched Recipe Name:", recipeData.name); 
            setRecipe(response.data);
        } catch (error) {
            console.error("Error fetching random recipe:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleLikeDislike = async (liked) => {
        try {
            const userId = "66339b9ee92918533120cb15"; // Replace with actual user ID
            const data = {
                recipeId: recipe._id,
                userId: userId,
                liked: liked ? 1 : 0,
            };
            await axios.post('http://0.0.0.0:8080/like-recipe', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            alert(`You have ${liked ? 'liked' : 'disliked'} the recipe.`);
        } catch (error) {
            console.error("Error sending like/dislike:", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!dataLoaded) {
        return <button onClick={fetchRandomRecipe}>Load Recipe</button>;
    }

    if (!recipe) {
        return <div>No Recipe Found</div>;
    }

    return (
        <div className="recipe-card">
            <h2>{recipe.name}</h2>
            <p>{recipe.summary}</p>
            <h3>Instructions</h3>
            <p>{recipe.directions}</p>
            <div className="buttons">
                <button onClick={() => handleLikeDislike(true)}>Like</button>
                <button onClick={() => handleLikeDislike(false)}>Dislike</button>
            </div>
        </div>
    );
};

export default RecipeCard;

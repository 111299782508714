import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

function MealGraph({ anomalies }) {
    const data = {
        labels: Array.from({ length: 24 }, (_, i) => i),
        datasets: [
            {
                label: 'Baseline',
                data: anomalies.map(a => a.baseline_value),
                borderColor: 'blue',
                fill: false,
            },
            {
                label: 'User Log',
                data: anomalies.map(a => a.user_value),
                borderColor: 'orange',
                fill: false,
            }
        ]
    };

    return (
        <div>
            <Line data={data} />
        </div>
    );
}

export default MealGraph;

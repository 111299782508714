import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Chat from './components/Chat';
import Imaging from './components/Imaging';
import Home from './components/Home';
import SuggesticAI from './components/SuggesticAI';
import ChatScreen from './components/ChatScreen';
import MealPlannerScreen from './components/MealPlanner/MealPlannerScreen';
import RecipeCard from './components/MealPlanner/RecipeCard';
import MealPlan from './components/MealPlanner/MealPlan';
import MealPlannerV2 from './components/MealPlannerV2';
import CountryStateSelector from './components/CountryStateSelector';
import AnomalyDetection from './components/MealPlanner/AnomalyDetection';
import MindfulEating from './components/MindfulEating/MindfulEating';

function App() {
  return(
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" Component={Home}></Route>
        <Route path="/chat" Component={ChatScreen} />
        <Route path="/imaging" Component={Imaging} />        
        <Route exact path="/suggestic-ai" Component={SuggesticAI} />
        <Route exact path="/mealplanner-ai" Component={MealPlannerV2} />
        <Route exact path="/anomaly-ai" Component={AnomalyDetection} />
        <Route exact path="/mindful-eating" Component={MindfulEating} />
      </Routes>
    </Router>
  );
}

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px;
    margin: 10px;
    width: 300px;
  }
  
  .meal-plan-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  button {
    margin: 20px;
    padding: 10px 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/mealplan.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,aAAa;IACb,eAAe;IACf,6BAA6B;EAC/B;;EAEA;IACE,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".card {\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    padding: 20px;\n    margin: 10px;\n    width: 300px;\n  }\n  \n  .meal-plan-container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-around;\n  }\n  \n  button {\n    margin: 20px;\n    padding: 10px 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './RecipeCard.css'; // Import some basic styling

const UserRecipeCard = (recipe_) => {
    const [recipe, setRecipe] = useState(recipe_.recipe_);
    const [loading, setLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        if (recipe) {
            console.log("Loaded Recipe", recipe);
            setDataLoaded(true);
        }
    }, [recipe]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!recipe) {
        return <div>No Recipe Found</div>;
    }

    return (
        <div className="recipe-card">
            <h2>{recipe.name}</h2>
            <p>{recipe.summary}</p>
            <div style={{flexDirection: 'row'}}>
                <div>
                    <h4>Calories</h4>
                    <p>{recipe.calories}</p>
                </div>
                <div>
                    <h4>Protein</h4>
                    <p>{recipe.protein_g}</p>
                </div>
                <div>
                    <h4>Fat</h4>
                    <p>{recipe.fat_g}</p>
                </div>
                <div>
                    <h4>Carbs</h4>
                    <p>{recipe.carbohydrates_g}</p>
                </div>
            </div>
        </div>
    );
};

export default UserRecipeCard;

import React, { useState } from 'react'
import UserSelector from '../UserSelector';
import MealLogsInput from '../MealLogsInput';
import MealGraph from '../MealGraph';

const AnomalyDetection = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [anomalies, setAnomalies] = useState([]);

    const handleUserSelect = async (userId) => {
        setSelectedUser(userId);
        setAnomalies([]);
    };

    const handleLogsSubmitted = (data) => {
        setAnomalies(data.path);
    };

    return (
        <div>
            <h1>Meal Logger</h1>
            <UserSelector onSelectUser={handleUserSelect} />
            {selectedUser && <MealLogsInput userId={selectedUser} onLogsSubmitted={handleLogsSubmitted} />}
            {anomalies.length > 0 && <MealGraph anomalies={anomalies} />}
        </div>
    );
}

export default AnomalyDetection
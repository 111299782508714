// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meal-plan-container {
    padding: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
}

.form label {
    margin-bottom: 10px;
}

.form input {
    margin-left: 10px;
    margin-bottom: 10px;
}

button {
    padding: 10px 20px;
    cursor: pointer;
}

.meal-plan {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.meal-day {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    max-width: 300px;
}

.recipe-card {
    margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MealPlanner/MealPlan.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".meal-plan-container {\n    padding: 20px;\n}\n\n.form {\n    display: flex;\n    flex-direction: column;\n    max-width: 400px;\n    margin: 0 auto;\n}\n\n.form label {\n    margin-bottom: 10px;\n}\n\n.form input {\n    margin-left: 10px;\n    margin-bottom: 10px;\n}\n\nbutton {\n    padding: 10px 20px;\n    cursor: pointer;\n}\n\n.meal-plan {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    gap: 20px;\n    margin-top: 20px;\n}\n\n.meal-day {\n    border: 1px solid #ccc;\n    padding: 20px;\n    border-radius: 8px;\n    max-width: 300px;\n}\n\n.recipe-card {\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

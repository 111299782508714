import React from 'react'
import chatArchitecture from "../assets/Temp_Chatbot_Architecture.jpeg"

const Home = () => {
  return (
    <div>
        <div>
        <h3>Chatbot Architecture</h3>
        <img src={chatArchitecture} alt="Chatbot Architecture"></img>
        </div>
    </div>
  )
}

export default Home
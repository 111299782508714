import React, { useState, useEffect } from 'react';
import Spinner from '../Spinner';

const MindfulEating = () => {
    const [members, setMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingAll, setLoadingAll] = useState(false);
    const [statusList, setStatusList] = useState({});

    useEffect(() => {
        // Fetch the list of members from your Flask server
        fetch('https://chatapi-staging.completumhealth.com/get-members')
            .then(response => response.json())
            .then(data => setMembers(data))
            .catch(error => console.error('Error fetching members:', error));
    }, []);

    const handleMemberSelect = (event) => {
        const memberIndex = event.target.value;
        setSelectedMember(members[memberIndex]);
        setSelectedIndex(memberIndex);
    };

    const handleGenerateReport = () => {
        if (selectedMember) {
            setLoading(true);
            fetch(`https://chatapi-staging.completumhealth.com/generate-report/${selectedMember._id}`, {
                method: 'GET',
            })
            .then(response => {
                // Check if the response is an error (status code 500 or other)
                if (!response.ok) {
                    throw new Error('Error generating report');
                }
                return response.blob();
                })
                .then(blob => {
                    // Create a link to download the PDF
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Report_${selectedMember.firstName}_${selectedMember.lastName}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    console.error('Error generating report:', error)
                });
        } else {
            alert('Please select a member.');
        }
    };

    const handleGenerateReport2 = async (member) => {
        // setLoading(true);
        await fetch(`http://10.0.0.21:8080/generate-report/${member._id}`, {
            method: 'GET',
        })
            .then(response => {
                // Check if the response is an error (status code 500 or other)
                if (!response.ok) {
                    throw new Error('Error generating report');
                }
                return response.blob();
            })
            .then(blob => {
                // Create a link to download the PDF
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Report_${member.firstName}_${member.lastName}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setStatusList(prevState => ({
                    ...prevState,
                    [member._id]: 'success'
                }));
            })
            .catch(error => {
                console.error('Error generating report:', error);
                setStatusList(prevState => ({
                    ...prevState,
                    [member._id]: 'error'
                }));
            })
            // .finally(() => setLoading(false));
    };

    const handleGenerateAllReports = async () => {
        setLoadingAll(true);
        for (let member of members.slice(0,3)) {
            setStatusList(prevState => ({
                ...prevState,
                [member._id]: 'pending'
            }));
            try {
                await handleGenerateReport2(member);
            } catch (error) {
                console.error('Error generating report for member:', member._id, error);
            }
        }
        // setLoadingAll(false);
    };

    return (
        <div>
            <h2>Select Member to Generate Report</h2>
            <select onChange={handleMemberSelect} value={selectedIndex}>
                <option value="">Select a member</option>
                {members.map((member, index) => (
                    <option key={member._id} value={index}>
                        {member.firstName + " " + member.lastName}
                    </option>
                ))}
            </select>
            {
                loading ? <Spinner></Spinner> : <button onClick={handleGenerateReport}>Generate Report</button>
            }
            {/* Download All Button */}
            <button onClick={handleGenerateAllReports} disabled={loading}>
                Download All
            </button>


            {loadingAll ?            
            <ul>
                {members.map(member => (
                    <li key={member._id} style={{ color: statusList[member._id] === 'success' ? 'green' : statusList[member._id] === 'error' ? 'red' : 'black' }}>
                        {member.firstName + " " + member.lastName}
                        {statusList[member._id] === 'pending' && ' (Generating...)'}
                    </li>
                ))}
            </ul>
            : <div></div>
            }
        </div>
    );
};

export default MindfulEating;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* OtherComponentStyles.css */
.container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .input,
  .textarea,
  .radio,
  .range {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .radio {
    width: auto;
  }
  
  .range-wrapper {
    display: flex;
    align-items: center;
  }
  
  .range {
    margin-right: 10px;
  }
  
  .read-only {
    background-color: #f3f3f3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/chatsettings.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;;;;IAIE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* OtherComponentStyles.css */\n.container {\n    padding: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  .label {\n    display: block;\n    margin-bottom: 5px;\n    font-weight: bold;\n  }\n  \n  .input,\n  .textarea,\n  .radio,\n  .range {\n    width: 100%;\n    padding: 8px;\n    margin-bottom: 20px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .radio {\n    width: auto;\n  }\n  \n  .range-wrapper {\n    display: flex;\n    align-items: center;\n  }\n  \n  .range {\n    margin-right: 10px;\n  }\n  \n  .read-only {\n    background-color: #f3f3f3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

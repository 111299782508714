// CountryStateSelector.js
import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete } from '@react-google-maps/api';

const CountryStateSelector = () => {
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [countryAutocomplete, setCountryAutocomplete] = useState(null);
  const [stateAutocomplete, setStateAutocomplete] = useState(null);

  const googleMapsScript = useRef(null);
  const apiKey = "AIzaSyBKvWsSVopNnJFZ_uagSYHes6LniSWfqJk";

  useEffect(() => {
    if (!googleMapsScript.current) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => googleMapsScript.current = true;
      document.body.appendChild(script);
    }
  }, [apiKey]);

  const handlePlaceChanged = (autocomplete, setter) => {
    const place = autocomplete.getPlace();
    setter(place);
  };

  return (
    <div>
      {googleMapsScript.current && (
        <>
          <Autocomplete
            onLoad={(autocomplete) => setCountryAutocomplete(autocomplete)}
            onPlaceChanged={() => handlePlaceChanged(countryAutocomplete, setCountry)}
            options={{
              types: ['(regions)'],
            }}
          >
            <input
              type="text"
              placeholder="Search for a country"
              style={{ width: '90%', height: '40px' }}
            />
          </Autocomplete>
          {/* {country && (
            <Autocomplete
              onLoad={(autocomplete) => setStateAutocomplete(autocomplete)}
              onPlaceChanged={() => handlePlaceChanged(stateAutocomplete, setState)}
            >
              <input
                type="text"
                placeholder="Search for a state/province"
                style={{ width: '90%', height: '40px', marginTop: '10px' }}
              />
            </Autocomplete>
          )} */}
        </>
      )}
    </div>
  );
};

export default CountryStateSelector;

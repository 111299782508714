import React from 'react';
import Chat from './Chat';
import ChatSettings from './ChatSettings';

const ChatScreen = () => {
  const containerStyle = {
    display: 'flex', // Enables Flexbox
    height: '100vh', // Full view height, adjust as needed
  };

  const componentStyle = {
    flex: '1', // Each child takes up an equal portion of the container
    // Add additional styling as needed (e.g., padding, border, etc.)
  };

  return (
    <div style={containerStyle}>
      <div style={componentStyle}><Chat /></div>
      <div style={componentStyle}><ChatSettings /></div>
    </div>
  );
}

export default ChatScreen;

import React from 'react'
import RestrictionsList from './RestrictionsList';
import MealPlanSettings from './MealPlanSettings';
import MealPlan from './MealPlan';
import { useEffect, useState } from 'react';

const SuggesticAI = () => {
    const [restrictions, setRestrictions] = useState([]);

    const fetchRestrictions = async () => {
      const query = `
        query {
          restrictions {
            edges {
              node {
                id
                name
                subcategory
                slugname
                isOnProgram
              }
            }
          }
        }
      `;
  
      try {
        const response = await fetch('https://production.suggestic.com/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token e0f38722a7a0600e4cdd982e92ad09a45a666ead', // Replace with actual auth token
            'sg-user': '1489d7f9-23c8-4169-927f-99d554bed5cd', // Replace with actual auth token
            // Add any other headers here
          },
          body: JSON.stringify({ query })
        });
  
        const jsonResponse = await response.json();
        console.log(jsonResponse);
  
        if (jsonResponse.errors) {
          console.log(jsonResponse.errors)
          } else {
          setRestrictions(jsonResponse.data.restrictions.edges.map(edge => edge.node));
          }
          } catch (err) {
            console.log(err)
          }
          };
  
    useEffect(() => {
            fetchRestrictions();
          }, []); // The empty array means this effect will only run once on mount
          
          
    return (
      <div>
        <h3>Select Dietary Restrictions</h3>
        <RestrictionsList restrictionsData={restrictions} />
        <h3>Meal Plan Settings</h3>
        <MealPlanSettings></MealPlanSettings>
        <h3>Generate Meal Plan</h3>
        <MealPlan></MealPlan>
      </div>
    );
}

export default SuggesticAI
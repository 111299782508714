// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recipe-card {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.recipe-card h2 {
    margin-top: 0;
}

.recipe-card .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.recipe-card button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007BFF;
    color: #fff;
    font-size: 16px;
}

.recipe-card button:hover {
    background-color: #0056b3;
}

.recipe-card button:nth-child(2) {
    background-color: #DC3545;
}

.recipe-card button:nth-child(2):hover {
    background-color: #c82333;
}
`, "",{"version":3,"sources":["webpack://./src/components/MealPlanner/RecipeCard.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,wCAAwC;AAC5C;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".recipe-card {\n    border: 1px solid #ccc;\n    padding: 20px;\n    border-radius: 8px;\n    max-width: 400px;\n    margin: 0 auto;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.recipe-card h2 {\n    margin-top: 0;\n}\n\n.recipe-card .buttons {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 20px;\n}\n\n.recipe-card button {\n    padding: 10px 20px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    background-color: #007BFF;\n    color: #fff;\n    font-size: 16px;\n}\n\n.recipe-card button:hover {\n    background-color: #0056b3;\n}\n\n.recipe-card button:nth-child(2) {\n    background-color: #DC3545;\n}\n\n.recipe-card button:nth-child(2):hover {\n    background-color: #c82333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
